import Center from "../components/Center";
import {useCurrentUserStore, useThemeStore} from "../lib/currentUserSerivce";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {startLoginProcess} from "../lib/apiCalls/Users";
import {DEFAULT_PAGE} from "../appConfig";
import MicrosoftLoginButton from "../components/Buttons/MicrosoftLoginButton";
import {BlockUI} from "primereact/blockui";
import {Tooltip} from "primereact/tooltip";
import {SwitchThemeButton} from "../components/SwitchThemeButton";
import {TabMenu} from "primereact/tabmenu";
import ButtonBase from "../components/Buttons/ButtonBase";
import {InputText} from "primereact/inputtext";
import {getServerUrl} from "../lib/utils";
import {invokeToast} from "../lib/toastToggler";
import {MdOutlineMailLock} from "react-icons/md"
import {SiMicrosoft} from "react-icons/si"

export default function LoginPage() {
    const router = useRouter();
    const currentUser = useCurrentUserStore((state) => state.currentUser);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (!!currentUser) {
            router.push(DEFAULT_PAGE);
        }
    }, [currentUser, router]);

    return (
        <Center>
            <SwitchThemeButton className="absolute right-0 top-0 m-10 cursor-pointer hover:scale-105 hover:rotate-12"/>

            <TabMenu model={[
                {label: 'MS Account', icon: <SiMicrosoft size={14} className="pi pi-fw mr-3" />},
                {label: 'Magic Link', icon: <MdOutlineMailLock size={24} className="pi pi-fw mr-3" />},
            ]}
                     activeIndex={activeIndex}
                     onTabChange={(e) => setActiveIndex(e.index)}
                     className="mb-4"
            />

            {activeIndex === 0 && <LoginWithMsAccountView/>}
            {activeIndex === 1 && <LoginWithMagicLink/>}
        </Center>
    );
}

function LoginWithMagicLink() {
    const [email, setEmail] = useState("");
    const [sending, setSending] = useState(false);

    const sendMagicLink = async () => {
        setSending(true);
        let ok = false;

        let errorMessage = "Please try again later.";

        try {
            const resp = await fetch(getServerUrl("/verify/generate-magic-link"), {
                method: "POST",
                body: JSON.stringify({
                    email
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (resp.status === 429) {
                errorMessage = (await resp.json()).error
            }

            ok = resp.ok;
        } catch (e) {
            console.error(e);
        } finally {
            setSending(false);
        }

        if (ok) {
            invokeToast("Magic link has been send.", "Please check your email.", "success")
            setEmail("");
        } else {
            invokeToast("Failed to send magic link", errorMessage, "error")
        }
    }

    return (<div className="flex flex-col items-center justify-center gap-1 w-[350px] h-[185px] surface-card rounded-3xl shadow-md">
        <div className="gap-1 flex flex-col">
            {/*<img*/}
            {/*    src="/logo.png"*/}
            {/*    alt="CapTime logo"*/}
            {/*    className="w-48 h-24 m-2 mb-6"*/}
            {/*/>*/}
            <label htmlFor="email" className="w-full text-start px-1 text-md font-thin">Email</label>
            <InputText className="" id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="john.doe@gmail.com" inputMode="email"/>
            <ButtonBase disabled={sending} onClick={sendMagicLink} className="w-full" label="Send link"></ButtonBase>
        </div>
    </div>)
}

function LoginWithMsAccountView() {
    const [isLoading, setIsLoading] = useState(false);
    const currentTheme = useThemeStore((state) => state.currentTheme);

    return (
        <>
            <div className="flex align-items-center justify-content-center w-[350px]">
                <div
                    className="surface-card p-4 shadow-md border-round w-full lg:w-6 flex-1 justify-center items-center flex flex-col rounded-3xl">
                    <img
                        src="/logo.png"
                        alt="CapTime logo"
                        className="w-48 h-24 m-2 mb-6"
                    />
                    {isLoading && (
                        <Tooltip target="#block" position="bottom">
                            <div>Can not open another authentication popup window.</div>
                        </Tooltip>
                    )}
                    <BlockUI blocked={isLoading} id="block">
                        <MicrosoftLoginButton
                            buttonTheme={currentTheme === "dark" ? "dark" : "light"}
                            buttonClassName={""}
                            onClick={async () => {
                                setIsLoading(true);
                                await startLoginProcess();
                                setIsLoading(false);
                            }}
                        />
                    </BlockUI>
                </div>
            </div>
        </>
    )
}